<template>
  <div>
    <h1 class="fd-text-lg fd-font-bold fd-text-black">{{ $t('invitations.rs.title') }}</h1>
    <p class="fd-text-sm fd-font-normal" style="color: rgba(0, 0, 0, 0.71);">
      {{ $t('invitations.rs.description') }}
    </p>

    <validation-observer ref="loginValidation">
      <b-form
        class="fd-mt-9"
        @submit.prevent
      >
        <b-form-group
          :label="$t('global.firstName')"
          label-for="firstName"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('global.firstName')"
            rules="required"
          >
            <b-form-input
              id="firstName"
              v-model="form.first_name"
              :state="errors.length > 0 ? false:null"
              name="firstName"
              :placeholder="$t('global.firstName')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="fd-mt-5"
          :label="$t('global.lastName')"
          label-for="lastName"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('global.lastName')"
            rules="required"
          >
            <b-form-input
              id="lastName"
              v-model="form.last_name"
              :state="errors.length > 0 ? false:null"
              name="lastName"
              :placeholder="$t('global.lastName')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="fd-mt-5"
          :label="$t('global.businessId')"
          label-for="businessId"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('global.businessId')"
            rules="required"
          >
            <b-form-input
              id="businessId"
              v-model="form.business_id"
              :state="errors.length > 0 ? false:null"
              name="businessId"
              :placeholder="$t('global.businessId')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="fd-mt-5"
          :label="$t('global.phoneNo')"
          label-for="phoneNo"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('global.phoneNo')"
            rules="required"
          >
            <b-form-input
              id="phoneNo"
              v-model="form.phone"
              :state="errors.length > 0 ? false:null"
              name="phoneNo"
              :placeholder="$t('global.phoneNo')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="fd-mt-5"
          :label="$t('global.email')"
          label-for="email"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('global.email')"
            rules="required|email"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              :state="errors.length > 0 ? false:null"
              name="email"
              :placeholder="$t('global.email')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="fd-mt-5"
          :label="$t('global.password')"
          label-for="password"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('global.password')"
            rules="required"
            vid="password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="password"
                v-model="form.password"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                :name="$t('global.password')"
                placeholder="••••••••••"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="fd-mt-5"
          :label="$t('global.confirmPassword')"
          label-for="confirmPassword"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('global.confirmPassword')"
            rules="required|confirmed:password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="confirmPassword"
                v-model="form.confirm_password"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                :name="$t('global.confirmPassword')"
                placeholder="••••••••••"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>

    <div class="fd-w-full fd-bg-white fd-flex fd-items-center fd-justify-end fd-mt-12">
      <b-button
        @click="decline" variant="outline-danger"
        class="fd-mr-4" style="padding-left: 40px !important; padding-right: 40px !important;"
      >
        {{ $t('invitations.rs.decline') }}
      </b-button>
      <b-button @click="create" variant="primary">{{ $t('invitations.rs.createAccountAndAcceptInvite') }}</b-button>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormGroup, BFormInput, BForm, BInputGroup, BButton } from 'bootstrap-vue'
import { required, email, confirmed } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: "Information",
  components: { ValidationProvider, ValidationObserver, BForm, BFormGroup, BFormInput, BInputGroup, BButton },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        business_id: '',
        phone: '',
        email: '',
        password: '',
        confirm_password: '',
      },
      required,
      email,
      confirmed
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    create() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$store.dispatch('invitations/createAccount', this.form)
        }
      })
    },
    decline() {
      this.$store.dispatch('invitations/decline')
    }
  }
}
</script>

<style lang="scss" scoped>
.invite {
  background: #FAFAFA url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjQ1IiB2aWV3Qm94PSIwIDAgMTAwIDQ1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTM0Ljg1MyAtNDAuNzUxOEw1MCA0My4xOTkyTC0zNC44NTI4IC00MC43NTE4IiBzdHJva2U9IiNGNEY3RjUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");

  & ::v-deep label {
    color: #5E5873 !important;
  }
}
</style>