var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"fd-text-lg fd-font-bold fd-text-black"},[_vm._v(_vm._s(_vm.$t('invitations.rs.title')))]),_c('p',{staticClass:"fd-text-sm fd-font-normal",staticStyle:{"color":"rgba(0, 0, 0, 0.71)"}},[_vm._v(" "+_vm._s(_vm.$t('invitations.rs.description'))+" ")]),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"fd-mt-9",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":_vm.$t('global.firstName'),"label-for":"firstName"}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.firstName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstName","state":errors.length > 0 ? false:null,"name":"firstName","placeholder":_vm.$t('global.firstName')},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"fd-mt-5",attrs:{"label":_vm.$t('global.lastName'),"label-for":"lastName"}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.lastName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastName","state":errors.length > 0 ? false:null,"name":"lastName","placeholder":_vm.$t('global.lastName')},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"fd-mt-5",attrs:{"label":_vm.$t('global.businessId'),"label-for":"businessId"}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.businessId'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"businessId","state":errors.length > 0 ? false:null,"name":"businessId","placeholder":_vm.$t('global.businessId')},model:{value:(_vm.form.business_id),callback:function ($$v) {_vm.$set(_vm.form, "business_id", $$v)},expression:"form.business_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"fd-mt-5",attrs:{"label":_vm.$t('global.phoneNo'),"label-for":"phoneNo"}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.phoneNo'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phoneNo","state":errors.length > 0 ? false:null,"name":"phoneNo","placeholder":_vm.$t('global.phoneNo')},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"fd-mt-5",attrs:{"label":_vm.$t('global.email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"email","placeholder":_vm.$t('global.email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"fd-mt-5",attrs:{"label":_vm.$t('global.password'),"label-for":"password"}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.password'),"rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":_vm.$t('global.password'),"placeholder":"••••••••••"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"fd-mt-5",attrs:{"label":_vm.$t('global.confirmPassword'),"label-for":"confirmPassword"}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.confirmPassword'),"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirmPassword","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":_vm.$t('global.confirmPassword'),"placeholder":"••••••••••"},model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"fd-w-full fd-bg-white fd-flex fd-items-center fd-justify-end fd-mt-12"},[_c('b-button',{staticClass:"fd-mr-4",staticStyle:{"padding-left":"40px !important","padding-right":"40px !important"},attrs:{"variant":"outline-danger"},on:{"click":_vm.decline}},[_vm._v(" "+_vm._s(_vm.$t('invitations.rs.decline'))+" ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.create}},[_vm._v(_vm._s(_vm.$t('invitations.rs.createAccountAndAcceptInvite')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }